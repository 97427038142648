<template>
  <v-app-bar
    id="app-navbar"
    :color="$vuetify.theme.dark ? '' : headerBgColor"
    dense
    elevate-on-scroll
    app
    :extension-height="childrenTwoCurrentRoute.length ? 96 : 48"
  >
    <div>
      <v-list-item class="pa-0 flex-column navbar__logotype__container">
        <v-img
          src="@/assets/images/logo.svg"
          width="140"
          height="38"
          class="navbar__module-logo"
          contain
        />

        <div class="navbar__module-name">
          {{ moduleName }}
        </div>
      </v-list-item>
    </div>

    <v-tabs dark show-arrows style="width: 0%">
      <v-tab v-for="item in items" :key="item.name" :to="{ name: item.name }">
        {{ item.title }}
      </v-tab>

      <v-spacer />
      <SignerStatus />
      <AccountMenu @openHelp="helpMenu = true" />
    </v-tabs>

    <v-navigation-drawer
      v-model="helpMenu"
      absolute
      temporary
      :permanent="helpMenuModalOpen"
      right
      width="350"
      height="80vh"
      style="top: 120px"
    >
      <!-- do not render this component unless navigation-drawer is shown -->
      <AsyncWrapper v-if="helpMenu" :handler="fetchHelpFiles">
        <v-list dense>
          <v-list-item v-for="file in helpFiles" :key="file.id">
            <v-list-item-content>
              <v-list-item-title class="text-center d-flex align-center">
                <v-tooltip v-if="$can('HF_U')" bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      icon
                      v-on="on"
                      @click="uploadHelpFileModal(file.key, file.name)"
                    >
                      <v-icon small>fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <div class="text-subtitle-1 ml-3">
                  <div class="d-flex flex-column align-start">
                    <v-btn
                      v-if="file.s3key"
                      :href="file.s3key"
                      class="px-2"
                      small
                      text
                      style="min-width: auto"
                      color="primary"
                      target="_blank"
                    >
                      {{ file.name }}
                    </v-btn>
                    <span v-else class="ml-2 d-inline-flex">
                      {{ file.name }}
                    </span>
                    <span
                      v-if="file.size && file.size > 0"
                      class="ml-2 d-inline-flex"
                      style="font-size: 0.75em; margin-top: -5px"
                    >
                      {{ formatFileSize(file.size) }}
                    </span>
                  </div>
                </div>
                <div />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </AsyncWrapper>
      <div v-if="$can('HF_ADD')" class="d-flex justify-center">
        <v-btn color="primary" class="px-12" @click="createUploadHelpFileModal">
          Добавить
        </v-btn>
      </div>
    </v-navigation-drawer>

    <template v-if="childrenCurrentRoute.length" #extension>
      <!-- Второй уровень модулей-->
      <div class="flex flex-column">
        <div
          class="d-flex flex-column"
          style="margin: 0 -16px; width: calc(100% + 32px)"
        >
          <v-tabs show-arrows>
            <v-tab
              v-for="child in childrenCurrentRoute"
              :key="child.name"
              :to="{ name: child.name }"
            >
              {{ child.title }}
            </v-tab>
          </v-tabs>
          <v-divider />
        </div>

        <!-- Третий уровень модулей-->
        <div
          v-if="childrenTwoCurrentRoute.length"
          class="d-flex flex-column"
          style="margin: 0 -16px; width: calc(100% + 32px)"
        >
          <v-tabs show-arrows>
            <v-tab
              v-for="child in childrenTwoCurrentRoute"
              :key="child.name"
              :to="{ name: child.name }"
            >
              {{ child.title }}
            </v-tab>
          </v-tabs>
          <v-divider />
        </div>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import AccountMenu from './AccountMenu.vue';
import SignerStatus from './SignerStatus.vue';
import AsyncWrapper from '@/components/AsyncWrapper.vue';

import env from '@/plugins/env';
import { mapGetters, mapActions } from 'vuex';
import { submenuForRoute, canRouteTo } from '@/utils/routing.ts';

const ENV_COLORS = {
  development: 'green',
  integration_v3: 'green',
  autotests_v3: 'green',

  // other environments
  undefined: 'primary',
};

export default {
  components: { AccountMenu, SignerStatus, AsyncWrapper },

  data: () => ({
    helpMenu: false,
    helpMenuModalOpen: false,
  }),

  computed: {
    ...mapGetters('AUTH', ['roleTypeName']),
    ...mapGetters('STRUCTURES', ['helpFiles']),

    menu() {
      return this.$store.getters.menu;
    },

    childrenCurrentRoute() {
      // Пересчитывается при изменении роута
      const routeMatched = this.$route.matched;
      return submenuForRoute(routeMatched[1]);
    },

    childrenTwoCurrentRoute() {
      // Пересчитывается при изменении роута
      const routeMatched = this.$route.matched;
      if (!this.childrenCurrentRoute.length || !routeMatched[2]) return [];
      return submenuForRoute(routeMatched[2], this.childrenCurrentRoute);
    },

    items() {
      return this.menu.filter(canRouteTo);
    },

    headerBgColor() {
      const currentEnv = env.get('VUE_APP_ENVIRONMENT');
      return ENV_COLORS[currentEnv] || ENV_COLORS[undefined];
    },

    moduleName() {
      return (this.roleTypeName || '').toUpperCase();
    },
  },

  methods: {
    ...mapActions('STRUCTURES', ['fetchHelpFiles']),
    uploadHelpFileModal(key, name) {
      this.helpMenuModalOpen = true;
      this.$openModal(
        'uploadHelpFile',
        { oldKey: key, oldName: name },
        {
          close: event => {
            !event?.action && (this.helpMenu = false);
            this.helpMenuModalOpen = false;
          },
        },
      );
    },
    createUploadHelpFileModal() {
      this.helpMenuModalOpen = true;
      this.$openModal('uploadHelpFile', null, {
        close: event => {
          !event?.action && (this.helpMenu = false);
          this.helpMenuModalOpen = false;
        },
      });
    },

    formatFileSize(bytes) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;

      if (bytes >= megabyte) return (bytes / megabyte).toFixed(1) + ' Мб';
      else if (bytes >= kilobyte) return (bytes / kilobyte).toFixed(1) + ' Кб';
      else return bytes + ' Байт';
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar__logotype__container {
  min-height: auto;
}

.navbar__module-logo {
  margin-top: -10px;
}

.navbar__module-name {
  position: absolute;
  margin-top: 18px;
  width: 100%;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
}
</style>
