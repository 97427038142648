<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['modals']),
  },

  render() {
    const modals = this.modals.map(modal =>
      this.$createElement(modal.component(), {
        key: modal.id,
        props: modal.props,
        on: modal.listeners,
        scopedSlots: modal.slots,
      }),
    );
    return this.$createElement('div', {}, modals);
  },
};
</script>
