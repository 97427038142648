<template>
  <v-footer padless color="transparent" style="width: 100vw">
    <v-col class="grey--text d-flex justify-space-between align-center">
      <v-col cols="3">
        <div>{{ moduleName }}</div>
        <div v-if="userGuideURL.terminal">
          <a target="_blank" :href="userGuideURL.terminal">
            Руководство пользователя ПО Терминал
          </a>
        </div>
        <div v-if="userGuideURL.dispatcher">
          <a target="_blank" :href="userGuideURL.dispatcher">
            Руководство пользователя ПО Диспетчер
          </a>
        </div>
      </v-col>
      <v-col class="text-center" cols="6">
        <div>
          <div>
            Позвонить бесплатно с мобильного телефона {{ ' ' }}
            <a href="tel:*1324">*1324</a>
          </div>
          <div>
            Бесплатный номер для звонков с любых телефонов {{ ' ' }}
            <a href="tel:88005507611">8-800-550-76-11</a>
          </div>
          <div
            class="text-decoration-underline"
            style="cursor: pointer; width: fit-content; margin: auto"
            color="primary"
            @click="openSupportModal"
          >
            Написать в поддержку
          </div>
        </div>
      </v-col>
      <v-col class="text-right" cols="3">
        <div>Medpoint24</div>
        <div>{{ copyright }}</div>
        <v-btn
          v-if="authDebug"
          text
          color="warning"
          @click="openAuthDebugModal"
        >
          Debug
        </v-btn>
      </v-col>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_GUIDE_URL } from '@/utils/constants.js';
import env from '@/plugins/env';

export default {
  data() {
    return {
      showSupportForm: false,
      copyright: '2017 – ' + new Date().getFullYear() + ' г. © DistMed.com',
      authDebug: env.get('VUE_APP_AUTH_DEBUG'),
    };
  },
  computed: {
    ...mapGetters('AUTH', ['roleTypeName']),

    userGuideURL: () => USER_GUIDE_URL,

    moduleName() {
      const name = this.roleTypeName
        ? ' - ' + this.roleTypeName.toUpperCase()
        : '';

      return 'MEDPOINT24' + name;
    },
  },
  methods: {
    openAuthDebugModal() {
      this.$openModal(() => import('@/components/debug/TheDebuggerModal'));
    },
    openSupportModal() {
      this.$openModal('support');
    },
  },
};
</script>
