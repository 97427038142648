<template>
  <v-menu offset-y bottom>
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" text height="100%" tile v-on="on">
        <v-layout column align-end>
          <div>
            {{ currentAccount.profile.surname }}
            {{ currentAccount.profile.name }}
          </div>
          <div class="text-caption" style="text-transform: initial">
            {{ currentAccount.role.name }}
          </div>
        </v-layout>
      </v-btn>
    </template>

    <v-list min-width="250px">
      <v-list-item
        :to="{
          name: 'cabinet',
        }"
      >
        <v-list-item-title>Профиль</v-list-item-title>
      </v-list-item>

      <v-divider />

      <v-list-item @click.stop="switchTheme">
        <v-list-item-action>
          <v-switch :input-value="$vuetify.theme.dark" @input="switchTheme" />
        </v-list-item-action>
        <v-list-item-title>Темная тема</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="permissionsForSigning" @click="chooseToken">
        <v-list-item-title>Способ подписи документов</v-list-item-title>
      </v-list-item>

      <v-list-item @click="$emit('openHelp')">
        <v-list-item-title>Помощь</v-list-item-title>
      </v-list-item>

      <v-divider />

      <v-list-item @click="signOutHandler">
        <v-list-item-title>Выход</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('AUTH', ['currentAccount', 'permissionsForSigning']),
    ...mapGetters('MEDCAB', ['isOnlineSSE']),
  },

  methods: {
    ...mapActions(['switchTheme']),
    ...mapActions('AUTH', ['signOut']),

    chooseToken() {
      this.$openModal('chooseSignatureToken');
    },
    signOutHandler() {
      if (this.isOnlineSSE) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Сначала остановите работу',
        });
        return;
      }
      this.signOut();
    },
  },
};
</script>
