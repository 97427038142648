<template>
  <v-tooltip v-if="permissionsForSigning && !isActiveToken" bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <v-btn style="height: 100%" text small @click="chooseToken">
          <v-icon color="red darkest-1">mdi-alert</v-icon>
        </v-btn>
      </div>
    </template>
    <span>Задайте способ подписи документов</span>
  </v-tooltip>

  <v-tooltip v-else-if="isSigning" bottom>
    <template #activator="{ on }">
      <div class="d-flex mr-10" v-on="on">
        <Spinner
          :size="20"
          color="white"
          style="margin: 0 !important"
          title=""
        />
      </div>
    </template>
    <span>
      Выполняется подпись документов...
      <template v-if="countSignedDocs">
        {{ countSignedDocs }} из {{ allCountDocs }}
      </template>
    </span>
  </v-tooltip>

  <v-tooltip v-else-if="isStoppedAfterMaxSigningRetries" bottom>
    <template #activator="{ on }">
      <div class="d-flex mr-10" v-on="on" @click="runSigning">
        <v-icon color="red">mdi-alert</v-icon>
      </div>
    </template>
    <span>
      Подпись документов приостановлена спустя несколько попыток
      <br />по причине повторяющихся ошибок. <br />Нажмите, чтобы запустить
      подпись документов.
    </span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from './Spinner';

export default {
  components: {
    Spinner,
  },

  computed: {
    ...mapGetters('AUTH', ['currentAccount', 'permissionsForSigning']),
    ...mapGetters('TOKENS', ['activeToken']),
    ...mapGetters('SIGNER', [
      'signerStatus',
      'countSignedDocs',
      'allCountDocs',
      'isStoppedAfterMaxSigningRetries',
    ]),

    isActiveToken() {
      return this.activeToken;
    },

    isSigning() {
      return this.signerStatus === 'signing';
    },
  },

  methods: {
    chooseToken() {
      this.$openModal('chooseSignatureToken');
    },

    runSigning() {
      this.$store.dispatch('SIGNER/initSigner');
    },
  },
};
</script>
