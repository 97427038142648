<template>
  <v-main>
    <div class="d-flex flex-column" style="height: 100%">
      <TheNavbar v-if="isAuthorized" />
      <div style="min-height: 77vh" class="flex-grow-1">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
      <TheFooter v-if="currentRouteMeta.showFooter && isAuthorized" />
      <TheModalContainer />
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import applyToken from '@/utils/mixins/applyToken';
import TheModalContainer from '@/components/TheModalContainer';
import TheNavbar from '@/components/TheNavbar';
import TheFooter from '@/components/TheFooter';

const DEFAULT_META = {
  showFooter: true,
};

export default {
  components: {
    TheModalContainer,
    TheNavbar,
    TheFooter,
  },

  mixins: [applyToken],

  computed: {
    ...mapGetters('AUTH', ['roleTypeName']),
    ...mapGetters('TOKENS', ['activeToken', 'activeTokenError']),
    ...mapGetters('SIGNER', ['signerError']),

    currentRouteMeta() {
      return { ...DEFAULT_META, ...this.$route.meta };
    },
  },

  watch: {
    roleTypeName: {
      immediate: true,
      handler() {
        const name = this.roleTypeName ? ' - ' + this.roleTypeName : '';

        document.title = 'MedPoint24' + name;
      },
    },

    activeToken: {
      immediate: true,
      handler(val) {
        if (val && this.permissionsForSigning)
          this.$store.dispatch('SIGNER/initSigner');
      },
    },

    activeTokenError(val) {
      if (val) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Проблема с сертификатом, документы не будут подписаны',
          text: val,
        });
      }
    },

    signerError(val) {
      if (val) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Подпись документов не удалась',
          text: val,
        });
      }
    },
  },

  async mounted() {
    this.checkSignatureToken();
  },
};
</script>
